"use client";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { Input, Button, useToast } from "@/components/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { usernameSchema } from "@/components/schema/Auth";
import { useSession } from "next-auth/react";
import { registerUser } from "@/services/auth";
import { Spinner } from "../Loading/Loading";

export default function GoogleRegister() {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const { data: session } = useSession();

  // Username form for new google registration
  const usernameForm = useForm({
    resolver: zodResolver(usernameSchema),
    defaultValues: { username: "" },
  });

  const googleFormData = new FormData();
  // Google signup submit handler
  async function onSend(values) {
    setLoading(true);
    const { name, email, id_token } = session;
    const names = name.split(" ");
    let firstname = "";
    let lastname;
    if (names.length > 1) {
      firstname = names.slice(0, names.length - 1).join(" ");
      lastname = names[names.length - 1];
    } else {
      firstname = names[0];
    }
    googleFormData.append("username", values.username);
    googleFormData.append("firstname", firstname);
    // Only append lastname if there is one
    if (lastname) googleFormData.append("lastname", lastname);
    googleFormData.append("email", email);
    googleFormData.append("provider", "google");
    googleFormData.append("googleIdToken", id_token);

    // Google Register user
    const { error, msg } = await registerUser(googleFormData);
    // Handle response

    if (!error) {
      toast({
        title: `Welcome ${values.username}`,
      });
      location.reload();
      setLoading(false);
    } else {
      if (msg.startsWith("Duplicate value entered for")) {
        const message = msg
          .split("Duplicate value entered for")[1]
          .split(" ")[1];
        toast({
          variant: "destructive",
          title: `Please select a different ${message}!`,
          description: `${message} : ${values[message]} is already in use!`,
        });
      } else {
        toast({
          variant: "destructive",
          title: `Error!`,
          description: msg,
        });
      }
      setLoading(false);
    }
  }
  return (
    <div className="flex items-center justify-center min-w-screen fullHeight  px-3">
      <div className="flex flex-col items-center w-full sm:max-w-[500px] space-y-7 px-3">
        <h1 className="text-3xl xs:text-3xl sm:text-4xl m-4 min-w-full font-semibold">
          Just one more step
        </h1>
        <div className="min-w-full space-y-3">
          <Form {...usernameForm}>
            <form
              onSubmit={usernameForm.handleSubmit(onSend)}
              encType="multipart/form-data"
              className="space-y-2 min-w-full"
            >
              <FormField
                control={usernameForm.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input className="customInput" {...field} />
                    </FormControl>
                    <FormDescription className="text-cyan-800">
                      This is your unique identifer
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="min-w-full">
                <Button
                  type="submit"
                  disabled={loading}
                  className="text-xl py-6 min-w-full "
                >
                  {loading ? <Spinner /> : "Register"}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
