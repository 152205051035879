'use client';
import axios from 'axios';
import Loading from '@/components/Loading/Loading';
import { useSession, signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, loginWithGoogle, getUser } from '@/features/user/userSlice';
import { useToast } from '@/components/ui';
import { logoutUser } from '@/features/user/userSlice';
import { userExist } from '@/services/auth';
import GoogleRegister from '@/components/Auth/GoogleRegister';
import { resetInterview } from '@/features/interview/interviewSlice';
export default function LoginLayout({ children }) {
  const { data: session, status } = useSession();
  const router = useRouter();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [newUser, setNewUser] = useState(false);

  if (status === 'unauthenticated') {
    dispatch(logoutUser());
    dispatch(resetInterview());

  }
  // Set token if logging with credentials
  useEffect(() => {
    // Function to login with google if account exists
    const loginUserWithGoogle = async (email) => {
      // checking if account exists
      const valid = await userExist(email);
      if (valid) {
        const { email, id_token, provider } = session;
        dispatch(loginWithGoogle({ email, id_token, provider }));
        router.push('/dashboard');
      } else {
        toast({
          title: `Success`,
          description: `Just one more step.`,
        });
        setNewUser(true);
      }
    };


    if (status === 'authenticated') {
      if (session?.provider === 'portify') {
        dispatch(setToken(session.token));
        dispatch(getUser(session.token));
        setTimeout(() => {
          router.push('/dashboard');
        }, 500);
      }
      if (session?.provider === 'google') {
        loginUserWithGoogle(session?.email);
      }
    }
  }, [status, router]);

  if (status === 'loading' || (status === "authenticated" && !newUser)) {
    return <Loading />;
  }

  if (status === "authenticated" && newUser) {
    return <GoogleRegister />
  }

  return children;
}
